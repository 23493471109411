<template>
  <v-container fluid class="px-11">
    <v-text-field
      class="mr-6"
      prepend-inner-icon="search"
      label="Search"
      v-model="search"
    ></v-text-field>
    <v-skeleton-loader
      type="table"
      :loading="pageLoaded"
      :transition="'slide-y-transition'"
    >
      <v-data-table
        hide-default-footer
        class="background mr-6"
        :headers="headers"
        :items="locationList"
      >
        <template v-slot:item.action="{ item }">
          <v-row justify="center">
            <v-tooltip top dark>
              <template v-slot:activator="{ on }">
                <div class="text-center">
                  <v-btn
                    small
                    elevation="0"
                    @click="openAddUsersDialog(item)"
                    slot="activator"
                    fab
                    dark
                    v-on="on"
                    color="primary"
                    :class="
                      $vuetify.breakpoint.width > 960
                        ? 'ml-0 mr-3 xs-small'
                        : 'ml-3 xs-small'
                    "
                  >
                    <v-icon small>fas fa-user-plus</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Add users to location</span>
            </v-tooltip>
            <v-tooltip top dark>
              <template v-slot:activator="{ on }">
                <div class="text-center">
                  <v-btn
                    small
                    elevation="0"
                    @click="editLocation(item)"
                    slot="activator"
                    fab
                    dark
                    v-on="on"
                    color="primary"
                    :class="
                      $vuetify.breakpoint.width > 960
                        ? 'ml-0 mr-3 xs-small'
                        : 'ml-3 xs-small'
                    "
                  >
                    <v-icon small>edit</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Edit Location</span>
            </v-tooltip>
          </v-row>
        </template>
      </v-data-table>
    </v-skeleton-loader>
    <v-pagination
      v-if="search.length === 0"
      class="mb-10"
      @input="goToPage"
      @next="nextPage"
      @previous="previousPage"
      total-visible="6"
      v-model="pagination.page"
      :length="pagination.pageCount"
    ></v-pagination>

    <users
      :closedOnce="userModalClosedOnce"
      v-if="addUsersDialog"
      :status="addUsersDialog"
      :location="selectedLocation"
      :loadingPage="true"
      @close-users-dialog="closeAddUsersDialog"
    ></users>
    <create-location-modal
      v-if="createLocation.modal"
      :status="createLocation.modal"
      :location="createLocation.selectedLocation"
      @close="
        createLocation.modal = false;
        createLocation.selectedLocation = null;
      "
      @location-created="handleLocationCreated"
      @location-updated="handleLocationUpdated"
      @location-delete="handleLocationDelete"
    ></create-location-modal>
    <DeleteModal
      :title="deleteDialog.title"
      :status="deleteDialog.status"
      :callback="deleteDialog.callback"
      :description="deleteDialog.description"
      @success="handleSuccessfulDelete"
      @close="
        deleteDialog.status = false;
        createLocation.modal = true;
      "
    />
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          @click="createLocation.modal = true"
          fab
          fixed
          dark
          bottom
          right
          color="primary"
          v-on="on"
        >
          <v-icon small>fas fa-plus</v-icon>
        </v-btn>
      </template>
      <span>Create a location</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { AuthAPI } from '../../clients/auth/auth.api'
import { LocationsAPI } from '@/clients/locations/locationsapi'
import { debounce } from 'debounce'
export default {
  data() {
    return {
      pageLoaded: false,
      locations: [],
      search: '',
      page: 1,
      pageCount: 0,
      headers: [
        { text: 'Name', value: 'name', align: 'left' },
        { text: this._is50c ? 'Client Number' : 'Address', value: this._is50c ? 'display_name' : 'address.formatted_address', align: 'left' },
        { text: 'Internal ID', value: 'internal_id', align: 'left' },
        { text: 'Actions', value: 'action', align: 'center', sortable: false }
      ],
      loading: false,
      selectedLocation: {},
      addUsersDialog: false,
      users: {},
      createLocation: {
        selectedLocation: null,
        modal: false
      },
      deleteDialog: {
        status: false,
        title: '',
        description: `Are you sure you want to delete this location? This action is not reversible.
        Proceed?`,
        callback: null,
        permissionId: null
      },
      pagination: {
        itemsPerPage: 10,
        page: 1,
        pageCount: 0,
        pageStart: 0,
        pageStop: 0
      },
      totalLocations: 0,
      userModalClosedOnce: false,
      searchResults: []
    }
  },
  components: {
    Users: () => import('@/views/Locations/users'),
    CreateLocationModal: () => import('./CreateLocationModal'),
    DeleteModal: () => import('@/components/custom-components/DeleteModal')
  },
  async mounted() {
    console.log(this.$route)
    await this.fetchLocations(this.pagination.page, this.pagination.itemsPerPage)
    if (this.$route.query.location_id) {
      this.handleLocationInUrl(this.$route.query.location_id)
    }
    // this.getLocations()
  },
  methods: {
    ...mapActions({
      va_setUsersforLocation: 'auth/setUsersforLocation'
    }),
    async fetchLocations(page, limit) {
      let response
      try {
        response = await LocationsAPI.getAllLocations(page, limit)
      } catch (error) {
        this.locations = []
        return
      }
      this.locations = response.data.payload.locations
      this.totalLocations = response.data.payload.total
      this.pagination.pageCount = response.data.payload.total_pages
    },
    fetchPagedListBack() {
      this.pagination.page -= 1
      this.fetchLocations()
    },
    fetchPagedList(page) {
      if (this.pagination.page !== page) {
        this.pagination.page = page
        this.fetchLocations()
      }
    },
    fetchPagedListForward() {
      this.pagination.page += 1
      this.fetchLocations()
    },

    goToPage(page) {
      this.fetchLocations(page, this.pagination.itemsPerPage)
    },
    nextPage() {
      if (this.pagination.page < this.pagination.length) {
        this.goToPage(this.pagination.page)
      }
    },
    previousPage() {
      if (this.pagination.page >= 1) {
        this.goToPage(this.pagination.page)
      }
    },
    async getLocations() {
      this.pageLoaded = true
      let response
      try {
        response = await AuthAPI.getUserLocations()
      } catch (error) {
        console.log(error)
        this.pageLoaded = false
        return
      }
      this.locations = response.data.payload
      this.pageLoaded = false
    },
    async openAddUsersDialog(location) {
      this.selectedLocation = location
      this.addUsersDialog = true
    },
    closeAddUsersDialog() {
      this.addUsersDialog = false
      this.selectedLocation = {}
      this.userModalClosedOnce = true
    },
    editLocation(loc) {
      this.createLocation.selectedLocation = loc
      this.createLocation.modal = true
    },
    handleLocationCreated(location) {
      this.locations.push(location)
      this.swal({
        title: 'Success',
        text: 'Location created successfully.',
        type: 'success'
      })
    },
    handleLocationUpdated(location) {
      this.getLocations()
      this.swal({
        title: 'Success',
        text: 'Location updated successfully.',
        type: 'success'
      })
    },
    handleLocationDelete(location) {
      this.createLocation.modal = false
      console.log(location)
      this.deleteDialog.status = true
      this.deleteDialog.title = `Are you sure you want to delete ${location.display_name}?`
      this.deleteDialog.callback = () => LocationsAPI.deleteById(this.vg_locationsApplicationDetails.app_details.api_url, this.vg_locationsApplicationDetails.app_details.api_headers['api-token'], location.id)
    },
    handleSuccessfulDelete() {
      this.createLocation.modal = false
      this.deleteDialog.status = false
      this.swal({
        title: 'Success',
        text: 'Location deleted successfully.',
        type: 'success'
      })
      this.getLocations()
    },
    async handleLocationInUrl(locId) {
      let res
      try {
        res = await LocationsAPI.getLocationById(locId)
      } catch (error) {
        return console.log(error)
      }
      this.selectedLocation = res.data.payload
      this.addUsersDialog = true
    },
    async handleSearch(term) {
      let res
      try {
        res = await LocationsAPI.searchLocation(term)
      } catch (error) {
        this.pageLoaded = false
        this.searchResults = []
        return console.log(error)
      }
      this.searchResults = res.data.payload
    }
  },
  computed: {
    ...mapGetters({
      vg_locationsApplicationDetails: 'applications/isLocationsEnabled'
    }),
    locationList() {
      if (this.search.length > 0) {
        return this.searchResults
      } else {
        return this.locations
      }
    }
  },
  watch: {
    search: {
      handler: debounce(function (x) {
        this.handleSearch(x)
      }, 500)
    }
  }
}
</script>

<style>
</style>
