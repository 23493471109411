var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-11",attrs:{"fluid":""}},[_c('v-text-field',{staticClass:"mr-6",attrs:{"prepend-inner-icon":"search","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-skeleton-loader',{attrs:{"type":"table","loading":_vm.pageLoaded,"transition":'slide-y-transition'}},[_c('v-data-table',{staticClass:"background mr-6",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.locationList},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-tooltip',{attrs:{"top":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',_vm._g({class:_vm.$vuetify.breakpoint.width > 960
                      ? 'ml-0 mr-3 xs-small'
                      : 'ml-3 xs-small',attrs:{"slot":"activator","small":"","elevation":"0","fab":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.openAddUsersDialog(item)}},slot:"activator"},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-user-plus")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Add users to location")])]),_c('v-tooltip',{attrs:{"top":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('div',{staticClass:"text-center"},[_c('v-btn',_vm._g({class:_vm.$vuetify.breakpoint.width > 960
                      ? 'ml-0 mr-3 xs-small'
                      : 'ml-3 xs-small',attrs:{"slot":"activator","small":"","elevation":"0","fab":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.editLocation(item)}},slot:"activator"},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Edit Location")])])],1)]}}])})],1),(_vm.search.length === 0)?_c('v-pagination',{staticClass:"mb-10",attrs:{"total-visible":"6","length":_vm.pagination.pageCount},on:{"input":_vm.goToPage,"next":_vm.nextPage,"previous":_vm.previousPage},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e(),(_vm.addUsersDialog)?_c('users',{attrs:{"closedOnce":_vm.userModalClosedOnce,"status":_vm.addUsersDialog,"location":_vm.selectedLocation,"loadingPage":true},on:{"close-users-dialog":_vm.closeAddUsersDialog}}):_vm._e(),(_vm.createLocation.modal)?_c('create-location-modal',{attrs:{"status":_vm.createLocation.modal,"location":_vm.createLocation.selectedLocation},on:{"close":function($event){_vm.createLocation.modal = false;
      _vm.createLocation.selectedLocation = null;},"location-created":_vm.handleLocationCreated,"location-updated":_vm.handleLocationUpdated,"location-delete":_vm.handleLocationDelete}}):_vm._e(),_c('DeleteModal',{attrs:{"title":_vm.deleteDialog.title,"status":_vm.deleteDialog.status,"callback":_vm.deleteDialog.callback,"description":_vm.deleteDialog.description},on:{"success":_vm.handleSuccessfulDelete,"close":function($event){_vm.deleteDialog.status = false;
      _vm.createLocation.modal = true;}}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","fixed":"","dark":"","bottom":"","right":"","color":"primary"},on:{"click":function($event){_vm.createLocation.modal = true}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-plus")])],1)]}}])},[_c('span',[_vm._v("Create a location")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }